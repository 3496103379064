import {fetchInstance} from "../wrappers/axios";
import {api} from "../config/api";
import {UserActions} from "../reducers/user";
import {show} from "redux-modal";
import {AppActions} from "../reducers/app";
import {getParameterByName} from "../utils/getParameterByName";

export const getGlobalUser = () => (dispatch) => {
  fetchInstance({
    method: "GET",
    url: api.getUserProfile,
  }).then((response) => {
    dispatch(UserActions.setGlobalProfile(response.data.data));
  });
};

export const getUserProfile = () => {
  return (dispatch, getState) => {
    const {userId} = getState().user;
    return fetchInstance({
      method: "GET",
      url: userId ? api.getUserProfileById(userId) : api.getUserProfile,
    }).then((response) => {
      if (userId) dispatch(getGlobalUser());
      dispatch(UserActions.setProfile(response.data.data));
      return response.data.data;
    });
  };
};

export const getUserFolios = (isFirstLoading = false, history) => {
  return (dispatch, getState) => {
    const {userId} = getState().user;
    fetchInstance({
      method: "GET",
      url: userId ? api.getUserFoliosById(userId) : api.getUserFolios,
    }).then((response) => {
      const data = response.data.data;
      // const data = [];
      dispatch(UserActions.setFolios(data));
      const redirect = getParameterByName("redirect");
      if (history && redirect) {
        history.push(redirect);
      } else if (isFirstLoading) {
        const folio = data.find(({is_default}) => is_default);
        if (folio) {
          dispatch(AppActions.setFolioId({id: folio.id}));
        } else if (data.length > 0) {
          dispatch(AppActions.setFolioId({id: data[0].id}));
        } else {
          dispatch(show("new-folio-templates"));
        }
      } else if (data.length === 0) {
        dispatch(show("new-folio-templates"));
      }
    });
  };
};

export const getAccountId = (isFirstLoading = false) => {
  return (dispatch) => {
    fetchInstance({
      method: "GET",
      url: api.getAccountId(4450),
    }).then((response) => {
      console.log(response);
      //dispatch(AppActions.setSetAccountId({id: data[0].id}));
    });
  };
};

export const updateUser = (data, userId) => (dispatch) =>
  fetchInstance({
    method: "PUT",
    url: api.updateUser(userId),
    data,
  }).then(() => dispatch(getUserProfile()));

export const updateAccount = (data, userId) => {
  return fetchInstance({
    method: "PUT",
    url: api.accountDetailsCRUD(userId),
    data,
  });
};

export const getShareSettings = () => {
  return (dispatch) => {
    return fetchInstance({
      method: "GET",
      url: api.shareSettings,
    }).then(({data}) => {
      dispatch(UserActions.setShareSettings(data.data));
    });
  };
};

export const updateShareSettings = (data) => {
  return (dispatch) => {
    fetchInstance({
      method: "POST",
      url: api.shareSettings,
      data,
    }).then((response) => {
      dispatch(UserActions.setShareSettings(response.data.data));
    });
  };
};

export const fetchNotifications = () => {
  return (dispatch) => {
    return fetchInstance({
      method: "GET",
      url: api.getNotifications,
    }).then((response) => {
      const notifications = response.data.data;
      const preparedNotifications = notifications
        .map((item) => {
          const {ifolio} = item;
          if (ifolio) {
            item.body = item.body.replace(`:ifolio_name`, ifolio.name);
          } else {
            item.body = item.body.replace(`:ifolio_name`, `Undefined IFOLIO`);
          }
          item.type = item.params.type;
          return item;
        })
        .sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
      dispatch(AppActions.setNotifications(preparedNotifications));
      return response.data.data;
    });
  };
};

export const fetchAllNotifications = (data) => {
  return new Promise((resolve, reject) => {
    fetchInstance({
      method: "POST",
      url: api.searchNotifications,
      data: data,
    })
      .then(resolve)
      .catch(reject);
  });
};

export const markNotificationsAsRead = (ids) => {
  return new Promise((resolve, reject) => {
    fetchInstance({
      method: "POST",
      url: api.markNotificationsAsRead,
      data: {
        notification_ids: ids,
      },
    })
      .then(resolve)
      .catch(reject);
  });
};

export const getPaypalPrice = (type) => {
  return fetchInstance({
    method: "GET",
    url: api.getPaypalPriceByType(type),
  });
};

export const getPaypalRedirect = ({accoundId, userId}) => {
  fetchInstance({
    method: "GET",
    url: api.getPaypalRedirect({accoundId, userId}),
  }).then((response) => {
    const url = response.data.data?.redirect_url;
    if (url) {
      window.location.href = url;
    }
  });
};

export const saveColors = ({type, list}) => (dispatch, getState) => {
  const state = getState();
  const {isTemplate} = state.app;
  dispatch(UserActions.setColors({type, list: list.slice(0, 14)}));
  if (!isTemplate && state?.user?.profile?.id) {
    fetchInstance({
      method: "PUT",
      url: api.updateUser(state.user.profile.id),
      data: {
        [type]: list.slice(0, 14),
      },
    });
  }
};

export const fetchPlans = (callback) => {
  return (dispatch) => {
    return fetchInstance({
      method: "POST",
      url: api.getPlanList,
      data: {
        filter: [
          {
            column: "is_default",
            value: true,
          },
        ],
        page: 1,
        limit: 1,
      },
    })
      .then((response) => {
        const plans = response.data.data;
        dispatch(AppActions.setPlans(plans));
        callback();
        return response.data.data;
      })
      .catch(() => {
        callback();
      });
  };
};

export const fetchPaymentMethod = (accountId) => {
  return fetchInstance({
    method: "GET",
    url: api.getPaymentMethodWithId(accountId),
  }).then((response) => {
    return response.data.data;
  });
};

export const fetchPaymentToken = () => {
  return fetchInstance({
    method: "GET",
    url: api.getPaymentToken,
  }).then((response) => {
    return response.data.client_secret;
  });
};

export const fetchStates = () => {
  return fetchInstance({
    method: "GET",
    url: api.getStates,
  }).then((response) => {
    return response.data.data;
  });
};

export const savePaymentMethod = (data) => {
  return fetchInstance({
    method: "PUT",
    url: api.getPaymentMethod,
    data,
  });
};

export const deletePaymentMethod = () => {
  return fetchInstance({
    method: "DELETE",
    url: api.getPaymentMethod,
  });
};

export const getWalletByAccountId = (accountId) => {
  return fetchInstance({
    method: "GET",
    url: api.getWallet(accountId),
  });
};

export const getWalletTransactions = (params, accountId) => {
  return fetchInstance({
    method: "GET",
    url: api.getWalletTransactions(accountId),
    params,
  });
};
