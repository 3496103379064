import React, {Fragment, memo} from "react";
import PropTypes from "prop-types";
import {Button, ButtonStyled, RoundedButton} from "../../components/Button";
import styled from "styled-components";

const Buttons = memo(({items, type, direction = "row", justifyContent = "center"}) => {
  return (
    <Wrapper direction={direction} justifyContent={justifyContent}>
      {items.map(({title, theme, action, icon, helperSelector, disabled}, i) => (
        <Fragment key={`button-top-${i}`}>
          {type === "rounded" ? (
            <RoundedButton
              options={{title}}
              theme={theme}
              onClick={action}
              icon={icon}
              helperSelector={helperSelector}
              disabled={disabled}
            />
          ) : (
            <Button
              options={{title}}
              theme={theme}
              onClick={action}
              icon={icon}
              helperSelector={helperSelector}
              disabled={disabled}
            />
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
});

Buttons.displayName = "Buttons";

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({direction}) => direction};
  align-items: ${({justifyContent}) => justifyContent};

  ${ButtonStyled} {
    margin-bottom: ${({direction}) => (direction === "row" ? "0" : "16px")};
    margin-right: ${({direction}) => (direction === "row" ? "16px" : "0")};
    :last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }
`;

Buttons.propTypes = {
  items: PropTypes.array.isRequired,
};

export default Buttons;
