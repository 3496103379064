import {createSlice} from "@reduxjs/toolkit";
import {defaultAvatar} from "../config/consts";

const prepareProfile = (payload) => {
  // Check avatar availability and in case of absense
  // Set default avatar
  payload.avatar = payload.avatar ?? defaultAvatar;
  return payload;
};

export const userSlice = createSlice({
  name: "user",
  initialState: {
    profile: {
      shareSettings: {},
    },
    folios: [],
    userId: null,
    accountId: null,
    globalProfile: {},
    tutorialSkip: {
      isFetching: false,
    },
  },
  reducers: {
    pendingSkipTutorial: (state) => {
      state.tutorialSkip.isFetching = true;
    },
    fulfilledSkipTutorial: (state) => {
      state.tutorialSkip.isFetching = false;
    },
    setProfile: (state, action) => {
      state.profile = prepareProfile(action.payload);
    },
    setFolios: (state, action) => {
      state.folios = action.payload;
    },
    setShareSettings: (state, action) => {
      state.profile.shareSettings = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    pushNewFolio: (state, action) => {
      state.folios.push(action.payload);
    },
    setAccountId: (state, action) => {
      state.accountId = action.payload;
    },
    setGlobalProfile: (state, action) => {
      state.globalProfile = action.payload;
    },
    setColors: (state, action) => {
      state.profile[action.payload.type] = action.payload.list;
    },
  },
});

export const UserActions = userSlice.actions;
