require("dotenv").config();

export const staticServerPort = 3005;

export const BASE_DOMAIN_URL = process.env.REACT_APP_BASE_DOMAIN_URL;
export const BASE_DOMAIN_URL_PRO = process.env.REACT_APP_BASE_DOMAIN_URL_PRO;
export const BASE_URL_DOMAIN = process.env.REACT_APP_BASE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v2.0";
export const BASE_SHARE_URL = process.env.REACT_APP_BASE_SHARE_URL;
export const BASE_URL_ANALYTICS = process.env.REACT_APP_BASE_URL_ANALYTICS;
export const BASE_URL_SCREENSHOTS = process.env.REACT_APP_FOLIO_SCREENSHOTS;
export const ADMIN_CLIENT_URL = process.env.REACT_APP_ADMIN_CLIENT_URL;
export const BASE_REDIRECT_STATIC_URL = process.env.REACT_STATIC_REQUEST_URL;
export const BASE_DOMAIN_STATIC = process.env.REACT_APP_STATIC_BASE_DOMAIN;
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;
export const SHARE_TEMPLATE_URL = process.env.REACT_APP_SHARE_TEMPLATE_URL;
export const BASE_LOCAL_URL = process.env.BASE_LOCAL_URL + "/api/v2.0";

// Pagination settings
export const SHARED_STATS_ITEMS_PER_PAGE = 50; // Default value will be 50

export const api = {
  getLayoutGroup: "/layoutGroups",
  getLayoutsByGroupId: (id) => `/layoutGroups/${id}`,
  getHeroLayouts:
    "/layoutGroups?with[relationships][layouts]&with[properties][layout]&type=heroes",
  getIfolioPresets: "/presets/search",
  createIfolioPresets: "/presets",
  getPresetConfig: (presetId) =>
    `/presets/${presetId}?with[properties][content]&with[properties][layout]&with[properties][props]`,
  setPresetConfig: (presetId) => `/presets/${presetId}`,
  getLayoutConfigById: (id) => `/layouts/${id}`,
  getLayouts: "/layouts/search",
  setLayoutDefaultContent: (layoutId) => `/layouts/${layoutId}/presets`,
  getEmailTemplateById: (templateId) => `/emailTemplates/${templateId}`,

  getFolioConfig: (folioId) =>
    `/ifolios/${folioId}?with[properties][content]&with[properties][layout]&with[properties][props]&with[properties][settings]`,
  getStaticFolioConfig: (folioId) =>
    `/ifolios/${folioId}?with[properties][content]&with[properties][layout]&with[properties][props]&with[properties][settings]`,
  getStaticFolioConfigRedirectIfolio: (folioId) =>
    `/ifolios/${folioId}/redirect-folio?with[properties][content]&with[properties][layout]&with[properties][props]&with[properties][settings]`,
  getFolioInfoByDomain: (domain) =>
    `/ifolios?filter[0][column]=custom_url&filter[0][operator]=like&filter[0][value]=${domain}&filter[0][boolean]`,
  setFolioConfig: (folioId) => `/ifolios/${folioId}`,
  deleteFolioConfig: (folioId) => `/ifolios/${folioId}`,
  searchFolio: "/ifolios/search",

  accountDetailsCRUD: (accountId) => `/accounts/${accountId}`,
  getEmailSmsCount: (accountId) => `/shares/getEmailSmsCount?account_id=${accountId}`,

  getMedia: "/medias",
  uploadMedia: "/medias",
  deleteMedia: (mediaId) => `/medias/${mediaId}`,
  getMediaMetaByHash: (hash) => `/medias/getByHash/${hash}`,

  setBlockConfig: (blockId) => `/blocks/${blockId}`,
  duplicateBlockConfig: (blockId) =>
    `/blocks/${blockId}/duplicate?with[properties][content]&with[properties][layout]&with[properties][props]`,
  removeBlockConfig: (blockId) => `/blocks/${blockId}`,
  addNewBlock: "/blocks",
  setHeroConfig: (folioId) => `/ifolios/${folioId}/hero`,
  setBlocksPositions: (folioId) => `/ifolios/${folioId}/setPositions`,
  setBlocksPositionsPreset: (folioId) => `/presets/${folioId}/setPositions`,
  createNewFolio: "/ifolios",

  getAccountByCustomUrl: (domain) =>
    `/accounts?filter[0][column]=custom_url&filter[0][value]=${domain}`,
  saveTopUpWallet: "/accounts",
  getWallet: (accountId) => `/wallet`,
  getWalletTransactions: (accountId) => `/wallet/${accountId}/transactions`,
  // getWallet: (accountId) => `/wallet/${accountId}`,

  authLogin: "/auth/login",
  authRefreshToken: "/auth/refreshToken",
  authLogout: "/auth/logout",
  authRegister: "/auth/register",
  authSendResetEmail: "/auth/resetPassword/sendResetEmail",
  authResetPassword: "/auth/resetPassword",
  authUpdatePassword: "/auth/updatePassword",
  authIsValidToken: "/auth/resetPassword/isValidToken",
  authInvite: "auth/invite/getEmail",

  registrationSteps: (step) => `/auth/registration/step/${step}`,

  getUserProfile: "/users/profile?with[relationships][accounts]&with_wallet=1",
  getUserProfileById: (userId) =>
    `/users/${userId}/profile?with[relationships][accounts]`,
  getUserFolios: "/users/ifolios",
  getUserFoliosById: (userId) => `/users/${userId}/ifolios`,
  getAccountId: (userId) => `/users/${userId}`,
  updateUser: (userId) => `/users/${userId}`,
  updateAllContactBlocks: `/users/updateAllContactBlocks`,

  shareMessage: "/share/message",
  followUpMessage: "/share/followUpMessage",
  shareSettings: "/share/settings",
  shareCreate: (folioId) => `/share/${folioId}`,

  getNotifications: "/notification/last",
  searchNotifications: "/notification/search",
  markNotificationsAsRead: "/notification/read",

  getCitiesList: "/search/cities",
  getSchoolsList: "/search/schools",
  getStatesList: "/search/states",

  // Plans
  getPlanList: "/plans/search",

  // Payment methods
  createPaymentTransaction: `/payment/stripe/pay`,
  validatePaymentTransaction: `/payment/stripe/validate`,
  confirmPaymentTransaction: `/payment/stripe/confirm`,
  getPaymentMethod: "/payment/method",
  getPaymentMethodWithId: (accountId) => `/payment/method/${accountId}`,
  getPaymentToken: "/payment/method/init",

  // Demo payment methods
  createDemoTransaction: "/payment/demo/pay",
  confirmDemoTransaction: "/payment/demo/confirm",

  // Geography
  getStates: "/location/states",

  getStatsUsers: (ifolio_id, startDate, endDate) =>
    `/stats/users?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsClicks: (ifolio_id, startDate, endDate) =>
    `/stats/clicks?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsSocial: (ifolio_id, startDate, endDate) =>
    `/stats/social?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsSessions: (ifolio_id, startDate, endDate) =>
    `/stats/sessions?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsDevices: (ifolio_id, startDate, endDate) =>
    `/stats/devices?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsNewUsers: (ifolio_id, startDate, endDate) =>
    `/stats/newusers?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsAvgTimeOnPage: (ifolio_id, startDate, endDate) =>
    `/stats/avgtimeonpage?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsPageViews: (ifolio_id, startDate, endDate) =>
    `/stats/pageviews?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsVideoPlay: (ifolio_id, startDate, endDate) =>
    `/stats/videoplay?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsVideoById: (ifolio_id, startDate, endDate, video_id) =>
    `/stats/videowatch?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}&video_id=${video_id}`,
  getStatsCompanies: (ifolio_id, startDate, endDate) =>
    `/stats/companies?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsCompanyById: (ifolio_id, startDate, endDate, company_id) =>
    `/stats/companyviews?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}&company_id=${company_id}`,
  getStatsSharedStats: (ifolio_id, startDate, endDate, sort = null, track = null) => {
    let path = `/stats/sharedstats?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}&items_per_page=${SHARED_STATS_ITEMS_PER_PAGE}`;
    if (sort) path += `&sort=${sort}`;
    if (track) path += `&track=${track}`;
    return path;
  },
  getStatsShareByTrack: (ifolio_id, track) =>
    `/stats/sharedstatstrack?ifolio_id=${ifolio_id}&track=${track}`,
  getStatsSharedStatsExport: (ifolio_id, startDate, endDate, tz) =>
    `/stats/sharedstats/export?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}&tz=${tz}`,
  getStatsLeaderboard: (startDate, endDate, accountId) =>
    `/stats/leaderboard?start_date=${startDate}&end_date=${endDate}&account_id=${accountId}`,
  getStatsMap: ({startDate, endDate, folioId, mode = "countries"}) =>
    `/stats/${mode}?start_date=${startDate}&end_date=${endDate}&ifolio_id=${folioId}`,
  getStatsScrollmap: ({folioId, startDate, endDate, visitId = null, track}) => {
    let path = `/stats/scrollmap?start_date=${startDate}&end_date=${endDate}&ifolio_id=${folioId}`;
    if (visitId) path += `&visit_id=${visitId}`;
    if (track) path += `&share_track=${track}`;
    return path;
  },
  getStatsDynamics: (ifolio_id, startDate, endDate) =>
    `/stats/dynamics?start_date=${startDate}&end_date=${endDate}&ifolio_id=${ifolio_id}`,
  getStatsInboundSms: () => `/inboundSms`,

  removeScreenshotById: (id) => `/screenshot/${id}`,
  removePresetScreenshotById: (id) => `/screenshot-preset/${id}`,
  cloneFolio: (id) => `/ifolios/clone/${id}`,
  // TRACKER MCSERVICE
  getIfolioByTrack: (track) => `/track/${track}`,
  // paypal
  getPaypalPriceByType: (type) => `/payment/price?type=${type}`,
  getPaypalRedirect: ({accoundId, userId}) =>
    `/payment/redirect?subscription=1&account_id=${accoundId}&user_id=${userId}`,
  getParentAccount: (accountId) => `/accounts/${accountId}/getParentAccount`,
  setIfolioMetategs: (ifolioId) => `/ifolios/${ifolioId}/meta-tags`,
};
