import React, {memo, useMemo} from "react";
import {getMedia} from "./actions/media";
import {MediaActions} from "./reducers/media";
import App from "./routes";
import Helper from "./wrappers/Helper";
import {sleep} from "./utils/sleep";
import {hide, show} from "redux-modal";
import {getIFolioPath} from "./config/paths";
import {history} from "./routes/history";
import {useDispatch, useSelector} from "react-redux";
import HelperContent from "./wrappers/Helper/HelperContent";
import {addingMediaAddition, editingBlocksAddition} from "./helperConfig";

const AppContainer = memo(() => {
  const folioId = useSelector((state) => state.app.folioId);
  const hasUserAnalytics = useSelector(
    (state) => state.folio.folioConfig?.account?.has_user_analytics
  );
  const folioConfig = useSelector((state) => state.folio.folioConfig);
  // const hideShareIcon = useSelector(
  //   (state) => state.folio?.folioConfig?.account?.settings?.hide_sharing
  // );
  const dispatch = useDispatch();
  const config = useMemo(() => {
    let tipsList = [
      // {
      //   action: async () => {
      //     dispatch(hide("hero-modal"));
      //   },
      //   selector: "hero-layout-choose",
      //   content: (
      //     <HelperContent
      //       title={"Editing Header"}
      //       text={
      //         "Edit the header design by clicking on the header layout button and selecting your design from the options. "
      //       }
      //       addition={editingHeaderAddition}
      //       options={{width: 305}}
      //     />
      //   ),
      //   position: "left",
      // },
      // {
      //   action: async () => {
      //     dispatch(fetchHeroLayouts());
      //     dispatch(show("hero-modal"));
      //     await sleep(1000);
      //   },
      //   selector: "hero-layout-choose-modal",
      //   content: (
      //     <HelperContent
      //       title={"Editing Header"}
      //       text={`Here you can adjust the <b>size</b>, <b>layout</b>, and <b>avatar</b> for the header. Add in a text box, logos, and more!`}
      //       options={{width: 300}}
      //     />
      //   ),
      // },
      {
        action: async () => {
          dispatch(hide("hero-modal"));
          await sleep(500);
        },
        selector: "editing-blocks-container",
        content: (
          <HelperContent
            title={"Creative Editor"}
            text={
              "Content can be easily added, edited, and moved around in blocks.  Each block gets it's own analytics.  Drag and drop to get it just right with these options."
            }
            addition={editingBlocksAddition}
            options={{width: 565}}
          />
        ),
        position: "top",
      },
      {
        action: async () => {
          dispatch(hide("layout-select"));
        },
        selector: "updating-block-layouts-container",
        content: (
          <HelperContent
            title={"Update Layouts"}
            text={
              "Choose from more layouts. Layouts adapt across device sizes and display images, videos, gifs, icons, html embeds, and text. Choose layout 20 for html embeds. You can even add animated charts to see how iFOLIO can visualize data in an animated, interactive way!"
            }
            options={{width: 320}}
          />
        ),
        position: "top",
      },
      // {
      //   selector: "animated-charts-container",
      //   action: async () => {
      //     dispatch(AppActions.setActiveLayoutsTab({label: "Media", index: 0}));
      //     await sleep(100);
      //     dispatch(show("layout-select"));
      //     await sleep(1000);
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Animated Charts"}
      //       text={
      //         "Check out the animated chart layouts! Visualize data in a beautiful, interactive way."
      //       }
      //       options={{width: 255}}
      //     />
      //   ),
      //   position: "bottom",
      //   ignoreChangePosition: true,
      // },
      // {
      //   selector: "selecting-charts-container",
      //   action: async () => {
      //     dispatch(AppActions.setActiveLayoutsTab({label: "Charts", index: 2}));
      //     await sleep(1000);
      //   },
      //   prevAction: async () => {
      //     dispatch(AppActions.setActiveLayoutsTab({label: "Media", index: 0}));
      //     await sleep(1000);
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Selecting Charts"}
      //       text={
      //         "Click on any option to change the layout of the block and create a chart."
      //       }
      //       options={{width: 255}}
      //     />
      //   ),
      //   ignoreChangePosition: true,
      // },
      // {
      //   selector: "editing-charts-container",
      //   action: async () => {
      //     dispatch(hide("layout-select"));
      //     await sleep(500);
      //   },
      //   prevAction: async () => {
      //     dispatch(AppActions.setActiveLayoutsTab({label: "Charts", index: 2}));
      //     dispatch(show("layout-select"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Editing Charts"}
      //       text={
      //         "Click on the “chart” icon to edit the type of chart, the display, and the data! You have a multitude of options to display data in an animated, interactive way."
      //       }
      //       options={{width: 315}}
      //     />
      //   ),
      //   position: "top",
      // },
      // {
      //   selector: "editing-text-toolbar",
      //   action: async () => {
      //     document.getElementById("editor-wrapper").focus();
      //     await sleep(100);
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Editing Text"}
      //       text={
      //         "Click on any text box to open the text editor. You can type or paste in text and use the editor to format it. Change the size, color, alignment, and more. You can also link any string of text to a website or a word document by highlighting it."
      //       }
      //       options={{width: 425}}
      //     />
      //   ),
      // },
      // {
      //   selector: "html-embeds-container",
      //   action: async () => {
      //     document.getElementById("editor-wrapper").focus();
      //     await sleep(100);
      //   },
      //   content: (
      //     <HelperContent
      //       title={"HTML Embeds"}
      //       text={
      //         "Want to embed a twitter feed, a calendar link, or a linked button? You can embed anything in an iFOLIO block by toggling on the HTML dial in the text editor. Turn that on, paste in your code, and hit “preview” to see how it embeds."
      //       }
      //       options={{width: 300}}
      //     />
      //   ),
      // },
      {
        action: async () => {
          dispatch(hide("media-library"));
        },
        selector: "adding-media-container",
        content: (
          <HelperContent
            title={"Adding Media"}
            text={
              "iFOLIO supports jpg, pngs, video links, gifs, docs, excel, web links and more! Upload images, videos, and gifs by clicking the “add media” icon.\n" +
              "You can change the way images display by using the cropping tool."
            }
            addition={addingMediaAddition}
            options={{width: 476}}
          />
        ),
        nextTitle: "Next",
      },
      {
        selector: "uploading-media-container",
        action: async () => {
          dispatch(
            show("media-library", {
              pathToContent: `media`,
              blockIndex: 0,
              maxElements: 1,
              selectedIndex: 0,
              allowTypes: [{label: "Images", value: "images"}],
            })
          );
          dispatch(
            MediaActions.setFilterByType({key: "leftMenuActiveItem", value: "stocks"})
          );
          const params = {
            type: "images",
            affiliation: "stocks",
            page: 1,
          };
          dispatch(getMedia(params, false, false, folioConfig?.account));
          await sleep(500);
        },
        prevAction: async () => {
          dispatch(hide("media-library"));
        },
        content: (
          <HelperContent
            title={"iFOLIO Media Libraries"}
            text={
              "iFOLIO comes with stock imagery, but you can upload images into your media library directly from your computer by clicking “upload image”. If you’d like to upload a video or a gif, click “link to video/gif” and paste a Youtube/Vimeo video link or a gif link."
            }
            options={{width: 245}}
          />
        ),
        position: "top",
      },
      {
        action: async () => {
          dispatch(hide("media-library"));
        },
        selector: "name-ifolio-container",
        content: (
          <HelperContent
            title={"Name your iFOLIO"}
            text={
              "Your iFOLIO name is both the title tag for SEO and is used to help you keep track of your iFOLIO’s."
            }
            options={{width: 284}}
          />
        ),
        ignoreChangePosition: true,
      },
      {
        selector: "publish-ifolio-container",
        content: (
          <HelperContent
            title={"Publishing your iFOLIO"}
            text={
              'Once your iFOLIO is ready to be shared, click "publish" to make that a live link. You won’t be able to share an iFOLIO until it is published.'
            }
            options={{width: 300}}
          />
        ),
        ignoreChangePosition: true,
        nextTitle: "Next",
      },
      // {
      //   selector: "publish-modal-container",
      //   action: async () => {
      //     dispatch(show("publish-modal"));
      //     await sleep(1000);
      //   },
      //   prevAction: async () => {
      //     dispatch(hide("publish-modal"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Publishing your iFOLIO"}
      //       text={
      //         "Here you will preview the text image that will deliver with your iFOLIO. Click on the “media” icon to upload another photo. Hit “publish” when you are ready and that link will be live! You can also give the link a custom name in the “friendly link” field."
      //       }
      //       options={{width: 340}}
      //     />
      //   ),
      //   position: "right",
      // },
      // {
      //   selector: "published-modal-container",
      //   action: async () => {
      //     const response = await publishUnpublishFolio({
      //       id: folioId,
      //       is_published: true,
      //     });
      //     dispatch(setFolioConfig(response.data.data));
      //     dispatch(AppActions.setFolioPublishStatus("publish"));
      //   },
      //   prevAction: async () => {
      //     const response = await publishUnpublishFolio({
      //       id: folioId,
      //       is_published: false,
      //     });
      //     dispatch(setFolioConfig(response.data.data));
      //     dispatch(AppActions.setFolioPublishStatus("process"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"iFOLIO Published"}
      //       text={
      //         "Your iFOLIO is now published and ready to be shared! You can click on the link to open the published page and hit “copy link” to get that on your clipboard to paste later."
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   position: "right",
      // },
      // {
      //   selector: "sharing-container",
      //   action: async () => {
      //     await sleep(1000);
      //     dispatch(hide("share-modal"));
      //     dispatch(hide("publish-modal"));
      //   },
      //   prevAction: async () => {
      //     dispatch(show("publish-modal"));
      //     await sleep(500);
      //     const response = await publishUnpublishFolio({
      //       id: folioId,
      //       is_published: true,
      //     });
      //     dispatch(setFolioConfig(response.data.data));
      //     dispatch(AppActions.setFolioPublishStatus("publish"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Sharing"}
      //       text={
      //         "Now that your iFOLIO link is published it is ready to be shared.\n" +
      //         "Click here to open the Share window for\n" +
      //         "1-to-1 shares and mass campaigns."
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   ignoreChangePosition: true,
      // },
      {
        selector: "share-modal-container",
        action: async () => {
          dispatch(show("share-modal"));
          await sleep(1000);
        },
        prevAction: async () => {
          dispatch(hide("share-modal"));
        },
        content: (
          <HelperContent
            title={"Sharing"}
            text={
              "Add the information of your recepient here in the share window. You can input their phone number, or email, or both. We recommend both as it increases the likelihood they will open the iFOLIO. You can customize the message and then hit “send”." +
              "<br/>" +
              "<br/>" +
              "• Leave the items in [brackets] intact<br/>" +
              "• You can add up to 10 people by hitting<br/>" +
              "• ”Add contact” and inputting their info<br/>" +
              "• You can update your own signature and it will autosave"
            }
            options={{width: 285}}
          />
        ),
        position: "left",
      },
      // {
      //   selector: "success-share-modal-container",
      //   action: async () => {
      //     dispatch(hide("share-modal"));
      //     dispatch(show("share-success-helper"));
      //     await sleep(500);
      //   },
      //   prevAction: async () => {
      //     dispatch(show("share-modal"));
      //     dispatch(hide("share-success-helper"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Successful Share!"}
      //       text={
      //         "You have successfully shared! Remember to open the iFOLIO link when it’s sent to you."
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      // },
      // {
      //   selector: "analytics-container",
      //   action: async () => {
      //     dispatch(hide("share-success-helper"));
      //   },
      //   prevAction: async () => {
      //     dispatch(show("share-success-helper"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Analytics"}
      //       text={
      //         "Once your link has been shared around, you can click into the “analytics” tab to view all the sets of data your iFOLIO is gathering."
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   ignoreChangePosition: true,
      // },
      // {
      //   selector: "shared-stats-container",
      //   action: async () => {
      //     history.push(getStatisticsPath(folioId));
      //     await sleep(500);
      //   },
      //   prevAction: async () => {
      //     history.push(getIFolioPath(folioId));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Shared Stats"}
      //       text={
      //         "From here you can see multiple tabs of data showing different metrics of your iFOLIO has gathered over time. Click “shared stats” to check the individual engagement of everyone you have shared your iFOLIO with!"
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      // },
      // {
      //   selector: "continue-editing-container",
      //   action: async () => {
      //     dispatch(AppActions.setActiveStatisticsTab("shared_stats"));
      //     history.push("/ifolio/statistics/" + folioId + "/shared_stats");
      //   },
      //   prevAction: async () => {
      //     dispatch(AppActions.setActiveStatisticsTab("summary"));
      //     // history.push("/ifolio/statistics/" + folioId + "/summary");
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Shared Stats"}
      //       text={
      //         "Below you can see when the individual opened your iFOLIO and how long they viewed it. Click on the “duration” number to see a scroll map of what they viewed. Click “continue editing” to return to edit mode."
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   ignoreChangePosition: true,
      // },
      {
        action: async () => {
          if (!hasUserAnalytics) {
            dispatch(hide("share-success-helper"));
          }
          dispatch(hide("share-modal"));
          history.push(getIFolioPath(folioId));
          await sleep(1000);
        },
        selector: "user-panel-container",
        content: (
          <HelperContent
            title={"User Panel"}
            text={
              "Click here to access your user panel. From here you can access your dashboard, create new iFOLIO’s, and adjust your settings for signatures, share messages, and share graphics.  Access FAQ videos and more."
            }
            options={{width: 297}}
          />
        ),
        ignoreChangePosition: true,
      },
      // {
      //   selector: "user-panel-open-container",
      //   action: async () => {
      //     dispatch(AppActions.setUserMenuOpen(true));
      //     await sleep(500);
      //   },
      //   prevAction: async () => {
      //     dispatch(AppActions.setUserMenuOpen(false));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"User Panel"}
      //       text={
      //         "Click “New iFOLIO” to purchase extra iFOLIO templates. Click “Dashboard” to update your settings and find helpful tips."
      //       }
      //       options={{width: 335}}
      //     />
      //   ),
      //   position: "left",
      //   nextTitle: "Dashboard",
      // },
      // {
      //   selector: "share-settings-container",
      //   action: async () => {
      //     dispatch(AppActions.setUserMenuOpen(false));
      //     dispatch(show("dashboard-modal"));
      //     await sleep(2000);
      //   },
      //   prevAction: async () => {
      //     history.push(getStatisticsPath(folioId));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Share Settings"}
      //       text={
      //         "Click here to adjust the settings for each time you share your iFOLIO."
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   nextTitle: "Share Settings",
      // },
      // {
      //   selector: "share-settings-signature-container",
      //   action: async () => {
      //     dispatch(AppActions.setActiveDashboardTab("share_settings"));
      //     await sleep(500);
      //   },
      //   prevAction: async () => {
      //     dispatch(AppActions.setActiveDashboardTab("my_ifolios"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Share Settings"}
      //       text={
      //         "Here you can change your default share message, signature, the graphics that deliver with your shares, and more!"
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   nextTitle: "TIPS/RESOURCES",
      // },
      // {
      //   selector: "tips-container",
      //   action: async () => {
      //     dispatch(AppActions.setActiveDashboardTab("tips"));
      //   },
      //   prevAction: async () => {
      //     dispatch(AppActions.setActiveDashboardTab("share_settings"));
      //   },
      //   content: (
      //     <HelperContent
      //       title={"Tips & Resources"}
      //       text={
      //         "If you ever get lost or have any questions, click on this tab for some helpful videos. You can also repeat this walkthrough here!"
      //       }
      //       options={{width: 320}}
      //     />
      //   ),
      //   nextTitle: "Next",
      // },
      {
        action: async () => {
          //dispatch(hide("dashboard-modal"));
        },
        prevAction: async () => {
          // dispatch(show("dashboard-modal"));
          // dispatch(AppActions.setActiveDashboardTab("tips"));
          // await sleep(2000);
        },
        selector: null,
        content: (
          <HelperContent
            title={`You’re ready to<br/>Build your iFOLIO!`}
            options={{width: 270}}
          />
        ),
        width: 330,
        height: 210,
      },
    ];
    // if (hideShareIcon) {
    //   const removeSelectors = ["share-modal-container", "success-share-modal-container"];
    //   tipsList = tipsList.filter((item) => removeSelectors.indexOf(item.selector) === -1);
    // }
    if (!hasUserAnalytics) {
      const removeSelectors = [
        "analytics-container",
        "shared-stats-container",
        "continue-editing-container",
      ];
      tipsList = tipsList.filter((item) => !removeSelectors.includes(item.selector));
    }
    return tipsList;
  }, [dispatch, folioConfig?.account, folioId, hasUserAnalytics]);

  // useEffect(() => {
  //   dispatch(show("dashboard-modal"));
  // }, [dispatch]);

  return (
    <Helper config={config}>
      <App />
    </Helper>
  );
});

AppContainer.displayName = "AppContainer";

export default AppContainer;
