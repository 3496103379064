import {updatedAllContactBlocksFields, defaultContactWidgetData} from "../config/consts";
import {defaultImage} from "../config/consts";
import cloneDeep from "lodash/cloneDeep";
export const prepareAllContactBlocksData = (profileData) => {
  const {
    firstname,
    lastname,
    email,
    phone,
    company,
    job_title,
    // city_name,
    // state_name,
    avatar,
  } = profileData;

  let avatarObject = "";

  try {
    avatarObject = JSON.parse(avatar);
  } catch (e) {
    avatarObject = {
      src: defaultImage,
      cropOptions: {},
    };
  }

  const vCardData = {
    firstname: firstname + " " + lastname,
    organization_position: job_title,
    organization_name: company,
    phone,
    email,
  };
  const defaultData = cloneDeep(defaultContactWidgetData);
  const ops = [];
  Object.keys(updatedAllContactBlocksFields).forEach((fieldName) => {
    let value = vCardData[fieldName];
    const attributes = {
      font: "Avenir-light",
      size: "18px",
    };
    if (fieldName === "firstname") {
      attributes.bold = true;
    }
    if (fieldName === "organization_position") {
      attributes.italic = true;
    }

    if (value) {
      ops.push(
        {
          attributes,
          insert: `${encodeURIComponent(value)}`,
        },
        {
          insert: "%0A",
        }
      );
    }
  });

  defaultData.content.text.push({
    text: {
      ops,
    },
  });

  defaultData.content.media.push({
    src: avatarObject.src,
    crop: avatarObject.cropOptions,
    type: "image",
  });

  return defaultData;
};

export const checkIfRemovingIsAllowed = ({
  menuItem,
  isTemplate,
  isGlobalPreset,
  isContentEditor,
}) => {
  const permissions = {
    my_uploads: true,
    organisations: isContentEditor || isTemplate || !isGlobalPreset,
    stocks: isTemplate && isGlobalPreset,
  };
  return permissions[menuItem];
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const showErrorMessage = (errors, values) => {
  const output = [];
  Object.keys(errors).forEach((key) => {
    output.push(capitalizeFirstLetter(key).replaceAll("_", " ") + ":");
    errors[key].forEach((err, ix) =>
      output.push(` - ${values[key][ix].option_name.toUpperCase()} ` + err.message)
    );
  });
  return output.join(`\n`);
};

export const copyToClipboard = (content) => {
  const element = document.createElement("textarea");
  element.value = content;
  document.body.appendChild(element);
  element.select();
  document.execCommand("copy");
  document.body.removeChild(element);
};
