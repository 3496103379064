import React, {memo, useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import CheckIcon from "../../icons/CheckIcon";
import {theme} from "../../assets/theme/theme";
import {Icon} from "../Icon";

const Checkbox = memo(
  ({
    label,
    onChange,
    type = "normal",
    checked,
    iternalState,
    defaultChecked,
    theme,
    checkBorder,
    rowReverse,
    showLabel,
    disabled,
  }) => {
    const [checkboxValue, changeCheckboxValue] = useState(defaultChecked);

    useEffect(() => {
      changeCheckboxValue(defaultChecked);
    }, [defaultChecked]);

    const handleOnChange = useCallback(() => {
      if (disabled) {
        return;
      }

      const value = iternalState ? checkboxValue : checked;
      onChange(!value);
      if (iternalState) {
        changeCheckboxValue(!value);
      }
    }, [checkboxValue, checked, disabled, iternalState, onChange]);

    const value = useMemo(() => (iternalState ? checkboxValue : checked), [
      checkboxValue,
      checked,
      iternalState,
    ]);

    return (
      <Wrapper rowReverse={rowReverse} disabled={disabled}>
        <Check
          onClick={handleOnChange}
          checkBorder={checkBorder}
          type={type}
          checked={value}
          customTheme={theme}
          rowReverse={rowReverse}
          showLabel={showLabel}
          disabled={disabled}
        >
          <Icon icon={CheckIcon} color={type === "normal" ? "#fff" : "#00C4CC"} />
        </Check>
        <Label
          onClick={handleOnChange}
          type={type}
          customTheme={theme}
          rowReverse={rowReverse}
          showLabel={showLabel}
          disabled={disabled}
        >
          {showLabel && <>{label}</>}
        </Label>
        <input type="checkbox" onChange={handleOnChange} value={value} />
      </Wrapper>
    );
  }
);

Checkbox.displayName = "Checkbox";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({rowReverse}) => (!rowReverse ? "row" : "row-reverse")};
  cursor: ${({disabled}) => (disabled ? "default" : "pointer")};

  input {
    position: absolute;
    left: -9999px;
    visibility: hidden;
  }
`;

const CheckBG = (type, checked, disabled) => {
  if (disabled) {
    return theme.colors.backgroundAccent3;
  }
  switch (type) {
    case "normal":
      return checked ? "#00C4CC" : "#fff";
    case "invert":
      return "transparent";
    case "mobile":
      return "#ffffff";
    default:
      return "#ffffff";
  }
};

const Check = styled.div`
  width: ${({customTheme}) => (!customTheme.size ? "17px" : customTheme.size)};
  height: ${({customTheme}) => (!customTheme.size ? "17px" : customTheme.size)};
  border-radius: 2px;
  overflow: hidden;
  background-color: ${({type, checked, disabled}) => CheckBG(type, checked, disabled)};
  border: ${({checkBorder, type}) =>
    checkBorder
      ? checkBorder
      : type === "normal" || "custom" || "mobile"
      ? "1px solid #c5d9e8"
      : "none"};
  margin-right: ${({rowReverse, showLabel}) =>
    !rowReverse && showLabel ? "15px" : !showLabel ? "0" : "0"};
  padding: ${({customTheme}) => (customTheme.padding ? customTheme.padding : "2px")};
  cursor: ${({disabled}) => (disabled ? "default" : "pointer")};
  flex-shrink: 0;

  ${({checked}) =>
    !checked &&
    css`
      > div {
        display: none;
      }
    `};
  > div {
    width: 100%;
    height: 100%;
  }
`;

const LabelColor = (type) => {
  switch (type) {
    case "normal":
    case "custom":
      return "#6a7984";
    default:
      return "#ffffff";
  }
};

const Label = styled.div`
  color: ${({type}) => LabelColor(type)};
  font-size: ${({customTheme}) => customTheme.fontSize};
  font-family: ${({customTheme, theme}) =>
    !customTheme.fontFamily
      ? theme.fonts.fontFamily.helveticaNeueLight
      : customTheme.fontFamily};
  letter-spacing: 0.4px;
  cursor: ${({disabled}) => (disabled ? "default" : "pointer")};
  margin-top: 3px;
  margin-right: ${({rowReverse, showLabel}) =>
    rowReverse ? "16px" : !showLabel ? "0" : "0"};
`;

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["normal", "invert", "custom", "mobile"]),
  checked: PropTypes.any,
  defaultChecked: PropTypes.bool,
  iternalState: PropTypes.bool,
  theme: PropTypes.object,
  checkBorder: PropTypes.string,
  rowReverse: PropTypes.bool,
  showLabel: PropTypes.bool,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  defaultChecked: false,
  iternalState: false,
  onChange: () => null,
  showLabel: true,
  theme: {
    fontSize: "16px",
    size: "17px",
  },
  disabled: false,
};

export default Checkbox;
