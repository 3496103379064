import keyMirror from "fbjs/lib/keyMirror";
import dayjs from "dayjs";
import {BASE_DOMAIN_URL, BASE_DOMAIN_STATIC} from "../config/api";
import RangeHalfYearIcon from "../icons/RangeHalfYearIcon";
import RangeYearIcon from "../icons/RangeYearIcon";
import RangeMonthIcon from "../icons/RangeMonthIcon";
import RangeWeekIcon from "../icons/RangeWeekIcon";

export const defaultBlockInnerPaddings = {
  top: "20",
  right: "20",
  bottom: "28",
  left: "20",
};
export const defaultElementZeroMargins = {
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
};
export const defaultElementMargins = {
  top: "12",
  right: "12",
  bottom: "12",
  left: "12",
};
export const defaultTextPaddings = {
  top: "3",
  right: "3",
  bottom: "3",
  left: "3",
};
export const defaultIndentUnits = "px";

export const defaultImg = BASE_DOMAIN_URL + "/assets/placeholder.svg";

export const defaultContentEditorIcon =
  "/assets/images/content-editor-placeholder-icon.svg";

export const privacyPolicyLink = "https://ifoliocloud.com/privacy-policy";
export const termsAndConditionsLink = "https://ifoliocloud.com/termsconditions";

export const defaultContentEditorImage =
  "/assets/images/content-editor-placeholder-image.svg";
export const defaultContentEditorImageLayout21_22 =
  "/assets/images/content-editor-placeholder-image-layout21-22.svg";
export const defaultContentEditorIconLayout21_22 =
  "/assets/images/content-editor-placeholder-icon-layout21-22.svg";

export const defaultContentEditorImagesList = [
  defaultContentEditorIcon,
  defaultContentEditorImage,
  defaultContentEditorImageLayout21_22,
  defaultContentEditorIconLayout21_22,
];
export const defaultAvatar = JSON.stringify({
  src: BASE_DOMAIN_STATIC + "/assets/big_head_squared.svg",
  cropOptions: {},
});
export const defaultStaticImg = BASE_DOMAIN_URL + "/assets/invisible_placeholder.png";
export const progressiveImageWidth = 20;
export const incorrectPasswordText = "Incorrect password";

export const markerLineWidth = 6;
export const markerRadius = 2;
export const markerLineWidthEmpty = 2;
export const markerRadiusEmpty = 4;

export const DEFAULT_PHONE_LENGTH = 11;
export const DEFAULT_PHONE_CODE_LENGTH = 1;
export const DEFAULT_PHONE_CODE = `+1`;

export const ROLES = {
  ADMIN: "admin",
  MANAGER: "manager",
  USER: "user",
};

export const authParams = {
  client_id: "1",
  client_secret: "V8QWkQIcS5Wo8kV7bAI6OsiYWoNXnBmmDT7xvOmg",
};

export const validateRules = keyMirror({
  // required to fill
  required: undefined,
  // maximum (string - text length, number - value, array - array length)
  max: undefined,
  // minimum (string - text length, number - value, array - array length)
  min: undefined,
  // regexp
  regexp: undefined,
  // not an empty array
  notEmpty: undefined,
  // email
  email: undefined,
  // The field under validation must be present and not empty only when any of the other specified fields are not present.
  requiredWithout: undefined,
  // The field under validation must be present and not empty only when any of the other specified field is present.
  requiredWith: undefined,
  // The field under validation must be present and not empty only when any of the other specified fields contains
  // pattern. The code below means that field is required when message contains [Signature] string
  // rules: [`${validateRules.requiredWhenContains}:message:[Signature]`],
  requiredWhenContains: undefined,

  // TODO: custom or specific validation rules (4 rules below) should be defined in other place
  // The field under validation must be present and not empty only when specified field is an correct email address.
  // rules: [`${validateRules.requiredWhenContainsEmail}:contacts.email`] means that field is required when contacts
  // contains valid email address. Contacts can be an array, or string property
  requiredWhenContainsEmail: undefined,
  requiredIfEmpty: undefined,
  requiredWithoutContacts: undefined,
  requiredWithoutContactsPhone: undefined,
  // Field mus be url
  url: undefined,
  // The field under validation must contain pattern you provided
  //  rules: [`${validateRules.mustContain}:[Link]`],
  mustContain: undefined,
  // The field under validation must contain only numbers
  // with provided length
  // rules: [
  //   `${validateRules.phone}:11`,
  // ],
  phone: undefined,
});

export const formFieldTypes = keyMirror({
  string: undefined,
  number: undefined,
  boolean: undefined,
  date: undefined,
  time: undefined,
  object: undefined,
  array: undefined,
});

export const initialFormFieldValue = {
  [formFieldTypes.string]: "",
  [formFieldTypes.number]: null,
  [formFieldTypes.boolean]: false,
  [formFieldTypes.date]: dayjs(),
  [formFieldTypes.time]: "00:00",
  [formFieldTypes.object]: {},
  [formFieldTypes.array]: [],
};

export const defaultMediaElement = {type: "image", src: undefined};
export const defaultMediaAvatarElement = (shape = "circle") => ({
  type: "avatar",
  src: undefined,
  shape,
});
export const defaultTextElement = {text: {ops: []}};
export const defaultTitleElement = {
  text: {
    ops: [
      {
        attributes: {
          bold: true,
        },
        insert: "Enter your title here.",
      },
      {
        attributes: {
          align: "center",
          header: 1,
        },
        insert: "\n",
      },
    ],
  },
};
export const defaultDescriptionElement = {
  text: {
    ops: [
      {
        attributes: {
          color: "#888888",
        },
        insert: "Enter your description here.",
      },
      {
        insert: "\n",
      },
    ],
  },
};

export const itemClassName = "masonry-item";

export const sliderConfig = ({
  accessibility,
  adaptiveHeight,
  wrapAround,
  className,
  prevNextButtons,
  draggable,
  pageDots,
}) => ({
  accessibility,
  adaptiveHeight,
  autoPlay: false,
  pageDots,
  wrapAround,
  percentPosition: true,
  contain: true,
  cellAlign: "left",
  initialIndex: 1,
  arrowShape: {
    x0: 10,
    x1: 60,
    y1: 50,
    x2: 65,
    y2: 45,
    x3: 20,
  },
  className,
  prevNextButtons,
  resize: true,
  setGallerySize: true,
  draggable,
});

export const navSliderConfig = ({asNavFor}) => ({
  adaptiveHeight: true,
  accessibility: false,
  autoPlay: false,
  pageDots: false,
  wrapAround: false,
  contain: true,
  cellAlign: "left",
  prevNextButtons: false,
  asNavFor,
});

export const sliderClassName = "analytics-slider";
export const parallaxSliderClassName = "parallax-slider";

export const defaultContent = {
  media: [],
  text: [],
  custom: [],
};

export const defaultHeroProps = {
  height: 340,
  avatarShape: "circle",
  background: [],
};

export const themeBreakpoints = {
  xs: 360,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1260,
  xxl: 1400,
};

export const defaultImage = defaultImg;

export const heatmapColors = [
  "hsla(0, 100%, 50%, 0.7)",
  "hsla(50, 100%, 50%, 0.7)",
  "hsla(100, 100%, 50%, 0.7)",
  "hsla(150, 100%, 50%, 0.7)",
  "hsla(200, 100%, 50%, 0.7)",
];

export const heatmapNoViewsColor = "hsla(0, 0%, 50%, 0.3)";

export const fetchingListTypes = {
  pagination: "pagination-list",
  vertical: "vertical-infinite-list",
};

export const MAX_SLIDES_COUNT = 6;

export const shareTitle = "Default Share Message:",
  shareDesc = `Adjust the default subject line and message sent when sharing. Type [Name] to insert the recipient’s
       name, [Link] to insert the iFOLIO link, and [Signature] to insert your default signature. You can add up to 3 
       options by clicking on the buttons below and giving them a custom title.`,
  followTitle = "Default Follow-up Message:",
  followDesc = `Adjust the subject line and message sent when following up. Type [Name] to insert the recipient’s 
      name, [Link] to insert the iFOLIO link, and [Signature] to insert your signature. You can replace the [link] with
       any iFOLIO link as well. Add up to 3 options by clicking on the buttons below and giving them a custom title.`;

export const updatedAllContactBlocksFields = {
  firstname: "First Name",
  organization_position: "Organization position",
  organization_name: "Organization name",
  phone: "Phone number",
  email: "E-mail",
  /*lastname: "Last Name",
  organization_address: "Organization address",*/
};

export let defaultContactWidgetData = {
  content: {
    text: [
      {
        text: {
          ops: [
            {
              insert: "Enter your title here",
              attributes: {
                font: "Avenir-heavy",
                size: "24px",
                color: "#1565c0",
              },
            },
            {
              insert: "\n",
              attributes: {
                header: 2,
              },
            },
          ],
        },
      },
    ],
    media: [],
    custom: [],
  },
};

export const periodItems = {
  7: {
    text: "Week",
    icon: RangeWeekIcon,
  },
  14: {
    text: "2 Weeks",
    icon: RangeWeekIcon,
  },
  31: {
    text: "Month",
    icon: RangeMonthIcon,
  },
  92: {
    text: "3 Months",
    icon: RangeMonthIcon,
  },
  183: {
    text: "6 Months",
    icon: RangeHalfYearIcon,
  },
  366: {
    text: "Year",
    icon: RangeYearIcon,
  },
};

export const LS_FILTER_KEY = `LS_FILTER_KEY`;

export const administrativeRoles = ["admin", "manager"];

export const sentryIgnoredErrors = [
  "Calling PropTypes validators directly is not supported",
  "Loading chunk",
  "Loading CSS chunk",
  "Request failed",
  "The play() request was interrupted by a call to pause()",
];

export const shareGraphicsCropperOptions = {
  text_image_banner: 0.78,
  text_image_headshot: 1,
  email_banner: 4,
  email_headshot: 1,
};

export const sortingHandlerClassName = "sorting-dnd-handler";

export const paymentSteps = {
  PAYMENT_INITIAL: 1,
  PAYMENT_EMAIL: 2,
  PAYMENT_CARD: 3,
  PAYMENT_FAILED: 4,
  PAYMENT_SUCCESS: 5,
};

export const imageToScreenRelation = 0.6;
export const imageMaxHeight = `${imageToScreenRelation * 100}vh`;

export const bulletTypes = {
  tick2:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAOCAYAAAAi2ky3AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACtSURBVHgBpdDRCcIwEAbgu2Qh3/JY3MAN7LtV3MB2h/qsTqAbSASRuJM9vUCEVFty6Q8hOUg+fgIwMdv7c8Y7woSsb48SQR+AqFGQmS/ySQdAWY0ihKhuC9OIoX8In0XQECKCxhCO/+yNdceVdctcxDeqrm6hNJ55eBGV+8KcpIhv1M7NhS/woBGjZqmIbxQOlXU7hViHZgo7TEUiqI+FpCA/UB9LRQbDGC/JmzfoWnu0PN2moAAAAABJRU5ErkJggg==",
  round:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAPklEQVQImXXNwQnAIBSD4Q8Fh3CSjujR4py6gr14kAcNBEIS+KHgxcQ6uUDHDm7OMw4z+VFGxRP64YKvG/4BBvoR6F04doAAAAAASUVORK5CYII=",
  round2:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAfklEQVQYlY3QwQ3CMAyF4U89Q9WhIgbIUNmmCyD2aMUWhQHCxalCJSosvYv9W35+aq1qrZAwYwvNSPs8oIInMsZQjl4JRsKKqW13V6aYJXEiH6EOzsHYMJ6AF7wHf9aAR/Pwo264t2eWk2cWpD6eNYxfQzl6Zc/xEPgr9BX4B7rIeNMvkSJeAAAAAElFTkSuQmCC",
  rect:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAFUlEQVQImWNkYGD4z4AFMGETpJcEAJWIAQvRMj+nAAAAAElFTkSuQmCC",
  rect2:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAARUlEQVQoke3SMQ6AQAwDwQni/+V91xQgBF0OSljJSrVWCsNAJjIgidpvdKkqKFjb1p3A8lD2y9+Rmd/2mbqU9Ad+8OrtDVYVI7/6YWrAAAAAAElFTkSuQmCC",
  diamond:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAYAAAAfSC3RAAAAhUlEQVQokY3SQQ7CIBCF4a915U30DtareDH1fO51jxtoGsoUXjIh4c0D/jBSSkpVmvDAvN1ce4PgjBdSXqeR4AnvHCr1LOHgdeCCbxX84drsrpiW3JzyIUvE3GK644NbxHzEdD7wu0yRzwBTy1/VY9r6Ox0xFeZQY/8YjFzI1JvVmnl30R9G843nO0rtwAAAAABJRU5ErkJggg==",
  arrow:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAPCAYAAADQ4S5JAAABOUlEQVQokY2PPUsDQRRFzyZDwCIgiMVCSNy1lYidreXM1gb/gGAgTVoR0cJOK1sLtfMHzGstjaVRbPKhFjJgJQQsRHZsNhKCG/LgNY9377kXo3XbaL3svWeeLQDvwFNizDZzTGC0VsAAuM9uLSvykScoWJEf4Bx4A56Bx1m0wHtPYswi8AJEwAbQzKMVAKzIJ3AB7FmRWyvSyKMF3nsAEmOqWZeFLCaJMVvTtD9B9nADWCtyPemaGHOUCVuThE3gLq8sMAIO1cSh+dDt4pyjWCyilCKKIlZqtS/gFDixIt8qcw+BHeccaZoShiHr9boHroB9K+LGrmPCbq/fL5XLZWrVKpVKpQO0rUhnOldgtC4Br8PhMIzj2AEHwKUVSf8rooAGsBTH8RlwbEVGM4qjgFVgzYr0Zj2O5xfwzpYKK+x8IAAAAABJRU5ErkJggg==",
  tick:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAANCAYAAABy6+R8AAAAoUlEQVQokZXRMQtBcRTG4eemDFJGmZlsstt8AKvyJaSMzD6G0WSzWwxmE7NrNigl1+CvEO711hlO5/xOb+eN/KcI9X+APAYoZAWKmKGaFShjjU5WoIYtJlmBJg5YIvc+XCHx+pU2johR+XSxF6Bx6Ls444LWNxt57LFBH9dwZJjmfxQWHzV3D/KnKsFSgh1KacBDU5zQSFt8fmXsHuIiDboBbUceu5YKduoAAAAASUVORK5CYII=",
  remove:
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsSAAALEgHS3X78AAAAwklEQVQ4y63VwQ2EIBBA0e80YBmjN0uwFDvYVtwOLMUSPLG0YQWzF/awieiMkYQT5AXIT8DMSKpzUh3MjDszqQ5JdTYzmqQ6Ay9gB8Y+543A+HTdAKxAC7wFWArWAmvZcAfbgUXKicYoeoCNfc6bAETRGgYgv01e9Az7Az3oFQbQmJnrSmXpFKuCFZQr7BQ8QPG0Kjw8JJCGKynxdubtVLydeTuVSLQeVCLRelCJYB60Saoh7OqJBJjuYJWTTjz9BXwBY3YYyj18dPUAAAAASUVORK5CYII=",
};

export const profileSteps = {
  PROFILE: 1,
  TEMPLATE: 2,
};

export const footerImageSrc =
  "https://ifolio-cloud-s3.s3.amazonaws.com/img/Powered_by_iFOLIO.png";

export const passwordRegexpString =
  "(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])^[0-9a-zA-Z!@#$%^&*\\S]{8,}$";
export const passwordErrorMessage =
  "Password field must contain minimum 8 characters. At least 1 number, 1 lowercase letter, 1 uppercase letter and 1 special character";

export const iFOLIOcloudLink = "//www.ifoliocloud.com";

export const topUpError = "Top Up Bank Packages are switched off";

export const billingCardHolderRegex = /^((?:[A-Za-z]+ ?){1,3})$/;
export const billingAddressRegex = /^[a-zA-Z\d-.,\s]*$/;
export const billingPostalCodeRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

export const billingErrors = {
  cardHolderBlock: {
    cardHolder: {
      invalid: "Invalid cardholder",
      required: "Cardholder is required",
    },
  },
  cardBlock: {
    cardNumber: {
      invalid: "Invalid card number",
      required: "Card number is required",
    },
    cardExpiry: {
      invalid: "Invalid card expiry date",
      required: "Card expiry date is required",
    },
    cardCvc: {
      invalid: "Invalid card CVC-code",
      required: "Card CVC-code is required",
    },
  },
  termsBlock: {
    terms: {
      required: "Terms field is required",
    },
  },
  addressBlock: {
    address1: {
      invalid: "Invalid address line 1",
      required: "Address line 1 is required",
    },
    address2: {
      invalid: "Invalid address line 2",
    },
    city: {
      invalid: "Invalid city",
      required: "City is required",
    },
    postalCode: {
      invalid: "Invalid zip code.",
      required: "Zip code is required",
    },
    state: {
      required: "State is required",
    },
  },
};

export const dashboardBillingErrors = {
  cardHolderBlock: {
    cardHolder: {
      invalid: "Invalid cardholder",
      required: "Cardholder is required",
    },
  },
  cardBlock: {
    cardNumber: {
      invalid: "Invalid card number",
      required: "Card number is required",
    },
    cardExpiry: {
      invalid: "Invalid card expiry date",
      required: "Card expiry date is required",
    },
    cardCvc: {
      invalid: "Invalid card CVC-code",
      required: "Card CVC-code is required",
    },
  },
  addressBlock: {
    country: {
      required: "Country is required",
    },
    street: {
      invalid: "Invalid street",
    },
    city: {
      invalid: "Invalid city",
    },
    postalCode: {
      invalid: "Invalid zip code.",
      required: "Zip code is required",
    },
    state: {
      required: "State is required",
    },
  },
};

export const billingDesktopOptions = {
  style: {
    base: {
      fontSize: "14px",
      color: "rgba(31, 39, 65, .8)",
      fontFamily: "Trebuchet MS",
      letterSpacing: ".5px",
      "::placeholder": {
        color: "rgba(141, 171, 196, .6)",
      },
    },
    invalid: {
      color: "rgba(31, 39, 65, .8)",
    },
  },
};

export const billingMobileOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "rgba(31, 39, 65, .8)",
      fontFamily: "Trebuchet MS",
      letterSpacing: ".5px",
      "::placeholder": {
        color: "rgba(141, 171, 196, .6)",
      },
    },
    invalid: {
      color: "rgba(31, 39, 65, .8)",
    },
  },
};

export const billingRegexes = {
  cardHolder: billingCardHolderRegex,
  address1: billingAddressRegex,
  address2: billingAddressRegex,
  city: billingAddressRegex,
  postalCode: billingPostalCodeRegex,
};

export const dashboardBillingRegexes = {
  cardHolder: billingCardHolderRegex,
  street: billingAddressRegex,
  city: billingAddressRegex,
  postalCode: billingPostalCodeRegex,
};

export const billingBlocksErrors = {
  cardHolderBlock: "errorCardHolderBlock",
  cardBlock: "errorCardBlock",
  termsBlock: "errorTermsBlock",
  addressBlock: "errorAddressBlock",
};

export const dashboardBillingBlocksErrors = {
  cardHolderBlock: "errorCardHolderBlock",
  cardBlock: "errorCardBlock",
  addressBlock: "errorAddressBlock",
};

export const billingBlocksOrderDesktop = [
  "cardHolderBlock",
  "cardBlock",
  "termsBlock",
  "addressBlock",
];

export const billingBlocksOrderMobile = [
  "cardHolderBlock",
  "cardBlock",
  "addressBlock",
  "termsBlock",
];

export const dashboardBillingBlocksOrder = [
  "cardHolderBlock",
  "cardBlock",
  "addressBlock",
];

export const STATISTIC_DEFAULT_DATE_RANGE_VALUE = 7;

export const accountIdErrorMessage = (pageName) =>
  `To edit template ${pageName}, please select this template in the admin panel`;

export const emptyPlaceholderText = {
  text: {ops: [{insert: "%0A", attributes: {withPlaceholder: 1}}]},
};
