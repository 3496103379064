import {createSlice} from "@reduxjs/toolkit";
import {history} from "../routes/history";
import {getIFolioPath} from "../config/paths";

const initialState = {
  checkedToken: false,
  isAuth: false,
  folioId: null,
  isTemplate: false,
  access_token: "",
  refresh_token: "",
  notifications: [],
  plans: [],
  activeLayoutsTab: {label: "Media", index: 0},
  folioPublishStatus: "process",
  userMenuOpen: false,
  activeDashboardTab: "my_ifolios",
  //activeDashboardTab: "billing",
  activeStatisticsTab: "summary",
  templateId: null,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setFolioId: (state, action) => {
      if (action.payload.id && Number(action.payload.id)) {
        if (!action.payload.withoutRedirect) {
          // todo problems with redirect from auth
          setTimeout(() => {
            history.push(getIFolioPath(action.payload.id));
          });
        }
        state.folioId = action.payload.id;
        state.isTemplate = !!action.payload.isTemplate;
        localStorage.setItem("iFolioId", action.payload.id);
      }
    },
    setTokens: (state, action) => {
      state.access_token = action.payload.access_token;
      state.refresh_token = action.payload.refresh_token;
    },
    setAuth: (state, action) => {
      state.isAuth = action.payload;
    },
    setCheckedToken: (state) => {
      state.checkedToken = true;
    },
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
    setPlans: (state, action) => {
      state.plans = action.payload;
    },
    logout: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key];
      });
    },
    setActiveLayoutsTab: (state, action) => {
      state.activeLayoutsTab = action.payload;
    },
    setFolioPublishStatus: (state, action) => {
      state.folioPublishStatus = action.payload;
    },
    setUserMenuOpen: (state, action) => {
      state.userMenuOpen = action.payload;
    },
    setActiveDashboardTab: (state, action) => {
      state.activeDashboardTab = action.payload;
    },
    setActiveStatisticsTab: (state, action) => {
      state.activeStatisticsTab = action.payload;
    },
    setTemplateId: (state, action) => {
      state.templateId = action.payload;
    },
    setIsTemplate: (state, action) => {
      state.isTemplate = action.payload;
    },
  },
});

export const AppActions = appSlice.actions;
