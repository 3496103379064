import React, {memo, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import BlockWrapper from "../../../../modules/Block/BlockContainer";
import List from "../../../../components/List";
import SmallHeaderIcon from "../../../../icons/SmallHeaderIcon";
import MediumHeaderIcon from "../../../../icons/MediumHeaderIcon";
import LargeHeaderIcon from "../../../../icons/LargeHeaderIcon";
import {
  AvatarIconCircle,
  AvatarIconRect,
  AvatarIconRectVideo,
} from "../../../../icons/AvatarIcon";
import Checkbox from "../../../../components/Checkbox";
import cloneDeep from "lodash/cloneDeep";
import {useSelector} from "react-redux";
import Heroes1 from "../../../../icons/HeroPreviewIcons/Heroes1";
import Heroes2 from "../../../../icons/HeroPreviewIcons/Heroes2";
import Heroes3 from "../../../../icons/HeroPreviewIcons/Heroes3";
import Heroes4 from "../../../../icons/HeroPreviewIcons/Heroes4";
import Heroes5 from "../../../../icons/HeroPreviewIcons/Heroes5";
import Heroes1rect from "../../../../icons/HeroPreviewIcons/Heroes1rect";
import Heroes2rect from "../../../../icons/HeroPreviewIcons/Heroes2rect";
import Heroes3rect from "../../../../icons/HeroPreviewIcons/Heroes3rect";
import Heroes1rectVideo from "../../../../icons/HeroPreviewIcons/Heroes1rectVideo";
import Heroes2rectVideo from "../../../../icons/HeroPreviewIcons/Heroes2rectVideo";
import Heroes3rectVideo from "../../../../icons/HeroPreviewIcons/Heroes3rectVideo";

const heroes = {
  circle: {
    "Header 1": {
      icon: <Heroes1 />,
    },
    "Header 2": {
      icon: <Heroes2 />,
    },
    "Header 3": {
      icon: <Heroes3 />,
    },
    "Header 4": {
      icon: <Heroes4 />,
    },
    "Header 5": {
      icon: <Heroes5 />,
    },
  },
  rectangle: {
    "Header 1": {
      icon: <Heroes1rect />,
    },
    "Header 2": {
      icon: <Heroes2rect />,
    },
    "Header 3": {
      icon: <Heroes3rect />,
    },
    "Header 4": {
      icon: <Heroes4 />,
    },
    "Header 5": {
      icon: <Heroes5 />,
    },
  },
  "rectangle-video": {
    "Header 1": {
      icon: <Heroes1rectVideo />,
    },
    "Header 2": {
      icon: <Heroes2rectVideo />,
    },
    "Header 3": {
      icon: <Heroes3rectVideo />,
    },
    "Header 4": {
      icon: <Heroes4 />,
    },
    "Header 5": {
      icon: <Heroes5 />,
    },
  },
};

const sizes = [
  {
    title: "small",
    icon: <SmallHeaderIcon />,
    value: 340,
  },
  {
    title: "medium",
    icon: <MediumHeaderIcon />,
    value: 440,
  },
  {
    title: "large",
    icon: <LargeHeaderIcon />,
    value: 540,
  },
];

const getAvatarShape = (heroAvatarType) => {
  const avatarShape = [];
  if (heroAvatarType === "video") {
    avatarShape.push({
      icon: <AvatarIconRectVideo />,
      value: "rectangle-video",
    });
  } else {
    avatarShape.push({
      icon: <AvatarIconRect />,
      value: "rectangle",
    });
  }
  avatarShape.push({
    icon: <AvatarIconCircle />,
    value: "circle",
  });
  return avatarShape;
};

export const iterageLayout = (layout, shape) => {
  if (!layout) {
    return;
  }
  if (layout.content) {
    layout.content.map((item) => iterageLayout(item, shape));
  } else if (
    layout.widget === "media" &&
    layout.props &&
    layout.props.type === "avatar"
  ) {
    layout.props.shape = shape;
  }
};

const getShape = (layout, shape, config) => {
  if (layout.content) {
    layout.content.map((item) => getShape(item, shape));
  } else if (
    layout.widget === "media" &&
    layout.props &&
    layout.props.type === "avatar"
  ) {
    shape.shape = layout.props.shape;
  } else if (config && config.avatarShape) {
    shape.shape = config.avatarShape;
  }
};

const injectShape = (layouts, shape) => {
  return cloneDeep(layouts).map((layout) => {
    iterageLayout(layout.layout, shape);
    return layout;
  });
};

const HeroList = memo(({changeConfig, config}) => {
  const heroAvatarType = useSelector(
    (state) => state.folio.hero?.content?.media[0]?.type
  );
  const layouts = useSelector((state) => state.content.heroLayouts.data);

  const items = useMemo(() => {
    const items = [];

    injectShape(layouts, config.avatarShape).forEach(
      ({layout, name, id: layout_id}, idx) => {
        let shape = {shape: "circle"};
        getShape(layout, shape, config);
        const layoutIcon = heroes[shape.shape][name]?.icon;
        items.push({
          layout,
          title: name,
          icon: layoutIcon,
          value: JSON.stringify(layout),
          layout_id,
        });
      }
    );
    return items;
  }, [config, layouts]);

  return (
    <Wrapper>
      <Content>
        <BlockWrapper cols={7} rightGap={50}>
          <List
            title={"Size:"}
            items={sizes}
            itemsInRow={3}
            selectedValue={config.height}
            onClick={(e) => changeConfig("height", e)}
          />
        </BlockWrapper>
        <BlockWrapper cols={5}>
          <div data-helper={"hero-layout-choose-modal"} />
          <List
            title={"Avatar shape:"}
            items={getAvatarShape(heroAvatarType)}
            itemsInRow={4}
            backplate={false}
            selectedValue={config.avatarShape}
            onClick={(e) => changeConfig("avatarShape", e)}
            controls={
              <Checkbox
                type={"invert"}
                label={"Border"}
                checked={config.avatarBorder}
                onChange={() => {
                  const avatarBorder = config.avatarBorder === 1 ? 0 : 1;
                  changeConfig("avatarBorder", {value: avatarBorder});
                }}
              />
            }
          />
        </BlockWrapper>
        <BlockWrapper cols={12}>
          <List
            title={"Design:"}
            items={items}
            selectedValue={config.layout}
            onClick={(e) => changeConfig("layout", e)}
          />
        </BlockWrapper>
      </Content>
    </Wrapper>
  );
});

HeroList.displayName = "HeroList";

const Wrapper = styled.div`
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 50px;
`;

HeroList.propTypes = {
  changeConfig: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

export default HeroList;
