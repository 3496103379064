import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";
import {ThemeProvider} from "styled-components";
import {theme} from "./assets/theme/theme";
import GlobalStyle from "./assets/global";
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

import "./styles/fontFace.scss";
import {SENTRY_DNS} from "./config/api";
import {history} from "./routes/history";
import {sentryIgnoredErrors} from "./config/consts";
import AppContainer from "./AppContainer";

// eslint-disable-next-line no-extend-native
String.prototype.hashCode = function () {
  let hash = 0,
    i,
    chr;
  if (this.length === 0) return hash;
  for (i = 0; i < this.length; i++) {
    chr = this.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash;
};

if (SENTRY_DNS) {
  Sentry.init({
    dsn: SENTRY_DNS,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event, hint) {
      const error = hint.originalException;
      if (error && error.message) {
        for (const errorString of sentryIgnoredErrors) {
          if (error.message.toLowerCase().indexOf(errorString.toLowerCase()) !== -1) {
            return null;
          }
        }
      }
      return event;
    },
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router history={history}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <AppContainer />
      </ThemeProvider>
    </Router>
  </Provider>
);
