import {
  endPendingFolioDelete,
  endPendingFolioDuplicate,
  FolioActions,
  startPendingFolioDelete,
  startPendingFolioDuplicate,
} from "../reducers/folio";
import {fetchInstance} from "../wrappers/axios";
import {api} from "../config/api";
import {prepareBlock, prepareStructureBlock} from "../utils/prepareData";

// import {history} from "routes/history";
import {AppActions} from "../reducers/app";
import {getUserFolios} from "./user";
import {prepareAllContactBlocksData} from "../utils/utils";
import {UserActions} from "../reducers/user";
import {fetchHeroLayouts, fetchLayoutGroups} from "../actions/content";
import omit from "lodash/omit";
import {toast} from "react-toastify";
import {iterageLayout} from "../pages/Folio/Modals/Hero/HeroList";
import {getSignalParams} from "../utils/getSignalParams";
// import {getIFolioPath} from "../config/paths";

// import {show} from "redux-modal";
// import {HARDCODEHEROES, HARDCODETEMPLATES} from "actions/HARDCODE";

const getFolioId = (getState) => getState().app.folioId;

export const fetchFolioConfig = ({isTemplate = false, id = null} = {}) => {
  return (dispatch, getState) => {
    const folioId = id || getFolioId(getState);
    if (folioId) {
      dispatch(FolioActions.pendingFolioConfig());
      fetchInstance({
        method: "GET",
        url: isTemplate ? api.getPresetConfig(folioId) : api.getFolioConfig(folioId),
      })
        .then((response) => {
          const hero = response.data.data.hero
            ? prepareBlock(response.data.data.hero, true)
            : null;

          if (hero) {
            iterageLayout(hero.layout, hero.props.avatarShape);
          }

          const data = response.data.data.template;
          const structure = data.map((block) => {
            return prepareStructureBlock(block);
          });
          dispatch(
            FolioActions.fulfilledFolioConfig({
              hero,
              structure,
              folioConfig: response.data.data,
            })
          );
          if (response.data.data?.account?.id) {
            dispatch(fetchAccountDetails(response.data.data.account.id));
            // fetchInstance({
            //   method: "GET",
            //   url: api.accountDetailsCRUD(response.data.data.account.id),
            // }).then((response) => {
            //   dispatch(FolioActions.fulfilledAccountDetails(response.data.data));
            // });
          }
        })
        .catch(() => {
          dispatch(FolioActions.rejectedFolioConfig());
        });
    }
  };
};

export const fetchAccountDetails = (accountId) => (dispatch) => {
  fetchInstance({
    method: "GET",
    url: api.accountDetailsCRUD(accountId),
  }).then((response) => {
    dispatch(FolioActions.fulfilledAccountDetails(response.data.data));
  });
};

export const setBlockConfig = (index, controller, is_content_dirty = 1) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {folioId: ifolio_id, isTemplate} = state.app;
    let blockConfig = state.folio.structure[index];
    const params = {};
    if (isTemplate) {
      params.preset_id = ifolio_id;
      blockConfig = omit(blockConfig, ["ifolio_id"]);
    } else {
      params.ifolio_id = ifolio_id;
    }
    if (blockConfig.id !== -1) {
      await fetchInstance({
        method: "PUT",
        url: api.setBlockConfig(blockConfig.id),
        ...getSignalParams(controller),
        data: {
          ...blockConfig,
          properties: blockConfig.props,
          ...params,
          is_content_dirty,
        },
      }).then(() => {
        //removeScreenshot(ifolio_id, isTemplate);
      });
    }
  };
};

export const duplicateBlockConfig = (index) => {
  return (dispatch, getState) => {
    const state = getState();
    // const {folioId: ifolio_id, isTemplate} = state.app;
    const {structure} = state.folio;
    const blockConfig = structure[index];

    dispatch(startPendingFolioDuplicate());
    fetchInstance({
      method: "POST",
      url: api.duplicateBlockConfig(blockConfig.id),
    }).then((response) => {
      dispatch(
        FolioActions.setElementProps({
          fullUpdate: true,
          path: `structure[${index + 1}]`,
          props: prepareBlock(response.data.data),
          blockIndex: index + 1,
        })
      );
      dispatch(setBlocksPositions());
      dispatch(endPendingFolioDuplicate());
      // removeScreenshot(ifolio_id, isTemplate);
    });
  };
};

export const removeBlockConfig = (index) => {
  return (dispatch, getState) => {
    const state = getState();
    // const {folioId: ifolio_id, isTemplate} = state.app;
    const blockConfig = state.folio.structure[index];

    dispatch(startPendingFolioDelete());
    fetchInstance({
      method: "DELETE",
      url: api.removeBlockConfig(blockConfig.id),
    }).then(() => {
      dispatch(endPendingFolioDelete());
      //removeScreenshot(ifolio_id, isTemplate);
    });
  };
};

export const addNewBlock = ({layout, isTemplate = false, cols = 0}) => {
  return (dispatch, getState) => {
    const state = getState();
    const ifolio_id = state.app.folioId;
    const preset_id = state.folio.folioConfig.id;
    const length = state.folio.structure.length - 1;
    return fetchInstance({
      method: "POST",
      url: api.addNewBlock,
      data: {
        layout_id: layout.id,
        ...(isTemplate ? {preset_id: +preset_id} : {ifolio_id}),
        name: layout.name,
        type: "template",
        cols,
      },
    }).then((response) => {
      dispatch(
        FolioActions.setElementProps({
          path: `structure[${length}]`,
          props: {id: response.data.data.id},
        })
      );
      return length;
      //removeScreenshot(ifolio_id, isTemplate);
    });
  };
};

export const setHeroConfig = (controller) => {
  return (dispatch, getState) => {
    const state = getState();
    const {hero} = state.folio;
    const {folioId, isTemplate} = state.app;
    const data = {
      ...hero,
      properties: hero.props,
    };
    if (isTemplate) {
      fetchInstance({
        method: "PUT",
        url: api.setBlockConfig(data.id),
        ...getSignalParams(controller),
        data: {
          ...omit(data, ["ifolio_id"]),
          preset_id: folioId,
        },
      }).then(() => {
        //removeScreenshot(folioId, isTemplate);
      });
    } else {
      fetchInstance({
        method: "PUT",
        url: api.setHeroConfig(folioId),
        ...getSignalParams(controller),
        data: {
          ...data,
          ifolio_id: folioId,
        },
      }).then(() => {
        //removeScreenshot(folioId, isTemplate);
      });
    }
  };
};

export const setBlocksPositions = () => {
  return (dispatch, getState) => {
    const state = getState();
    const {folioId: ifolio_id, isTemplate} = state.app;
    const {structure} = state.folio;
    const ids = structure.map(({id}) => id);
    if (!ids.some((id) => typeof id === "string")) {
      let params = {};
      if (!isTemplate) {
        params.specify_user = state.user.profile.id;
      }
      fetchInstance({
        method: "POST",
        url: isTemplate
          ? api.setBlocksPositionsPreset(ifolio_id)
          : api.setBlocksPositions(ifolio_id),
        data: {ids, ...params},
      });
    }
  };
};

export const createNewFolio = ({folioName, presetId, accountId}, handleHide) => {
  return (dispatch, getState) => {
    dispatch(FolioActions.startPendingFolioCreate());
    const {id} = getState().user.profile;
    fetchInstance({
      method: "POST",
      url: api.createNewFolio,
      data: {
        name: folioName,
        preset_id: presetId,
        account_id: accountId,
        user_id: id,
      },
    })
      .then(async (response) => {
        dispatch(FolioActions.clearFolio());
        dispatch(FolioActions.endPendingFolioCreate());
        const {id} = response.data.data;
        handleHide();
        dispatch(AppActions.setFolioId({id}));
        dispatch(getUserFolios());
      })
      .catch(() => dispatch(FolioActions.endPendingFolioCreate()));
  };
};

export const changeFolioConfig = ({index, params, config, isTemplate = false}) => {
  return (dispatch, getState) => {
    const folioConfig = config || getState().user.folios[index];
    const data = {id: folioConfig.id, ...params};
    fetchInstance({
      method: "PUT",
      url: isTemplate
        ? api.setPresetConfig(folioConfig.id)
        : api.setFolioConfig(folioConfig.id),
      data,
    }).then((response) => {
      dispatch(FolioActions.setFolioConfig(response.data.data));
      dispatch(getUserFolios());
      // dispatch(fetchFolioConfig());
      //removeScreenshot(folioConfig.id, isTemplate);
    });
  };
};

export const publishUnpublishFolio = (data) => {
  return fetchInstance({
    method: "PUT",
    url: api.setFolioConfig(data.id),
    data,
  });
};

export const setVCardData = (vCardData) => (dispatch, getState) => {
  const folioId = getFolioId(getState);
  return fetchInstance({
    method: "PUT",
    url: api.setFolioConfig(folioId),
    data: vCardData,
  }).then(({data}) => {
    dispatch(FolioActions.setVCardData(data.data?.vcard));
  });
};

export const deleteFolio = (id, handleHide) => {
  return (dispatch, getState) => {
    const state = getState();
    const {folioId} = state.app;
    fetchInstance({
      method: "DELETE",
      url: api.deleteFolioConfig(id),
    })
      .then(() => {
        dispatch(getUserFolios(id === folioId));
        handleHide && handleHide();
        toast.success("iFOLIO Deleted");
        //removeScreenshot(folioId, isTemplate);
      })
      .catch(() => handleHide && handleHide());
  };
};

export const getLayoutConfigById = (itemId, presetId) => {
  return fetchInstance({
    method: "GET",
    url: api.getLayoutConfigById(itemId),
    params: {
      "filter[0][column]": "preset_id",
      "filter[0][operator]": "=",
      "filter[0][value]": presetId,
    },
  });
};

export const updateAllContactBlocks = () => (dispatch, getState) => {
  const profileData = getState().user.profile;
  const vCardData = prepareAllContactBlocksData(profileData);

  return fetchInstance({
    method: "POST",
    url: api.updateAllContactBlocks,
    data: vCardData,
  });
};

export const cloneFolio = (dont_ask_again) => (dispatch, getState) => {
  const folioId = getState().app.folioId;
  return fetchInstance({
    method: "POST",
    url: api.cloneFolio(folioId),
    data: {
      dont_ask_again,
    },
  }).then((response) => {
    dispatch(UserActions.pushNewFolio(response.data.data));
    return response.data.data.id;
  });
};

export const getTemplateConfigById = (id) => (dispatch) => {
  dispatch(AppActions.setFolioId({id, withoutRedirect: true, isTemplate: true}));
  dispatch(fetchLayoutGroups());
  dispatch(fetchHeroLayouts());
  dispatch(fetchFolioConfig({isTemplate: true}));
};

export const setLayoutContent = (index, controller) => {
  return (dispatch, getState) => {
    const state = getState();
    const blockConfig = state.folio.structure[index];
    const templateId = state.app.templateId;
    fetchInstance({
      method: "POST",
      url: api.setLayoutDefaultContent(blockConfig.id),
      ...getSignalParams(controller),
      data: {
        preset_id: templateId,
        content: blockConfig.content,
      },
    });
  };
};

export const fetchContentEditorFolioConfig = (id) => {
  return (dispatch) => {
    dispatch(FolioActions.pendingFolioConfig());
    fetchInstance({
      method: "GET",
      url: api.getPresetConfig(id),
    }).then((response) => {
      dispatch(FolioActions.setFolioConfig(response.data.data));
    });
  };
};
