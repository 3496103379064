import {fetchInstance} from "../wrappers/axios";
import {api} from "../config/api";
import {ContentActions} from "../reducers/content";
import orderBy from "lodash/orderBy";
import {getTemplateConfigById} from "../actions/folio";
export const createPreset = ({accountId, presetId, handleHide}) => (dispatch) => {
  fetchInstance({
    method: "POST",
    url: api.createIfolioPresets,
    data: {
      name: "New Template",
      ...(accountId
        ? {accounts_ids: [+accountId], owner_account_id: +accountId}
        : {is_global: true}),
      preset_id: presetId,
    },
  }).then((response) => {
    const id = response.data.data.id;
    window.history.pushState(
      {},
      document.title,
      window.location.pathname.replace("create_new", id)
    );
    dispatch(getTemplateConfigById(id));
    handleHide();
  });
};

export const fetchLayoutGroups = () => {
  return (dispatch) => {
    dispatch(ContentActions.pendingLayoutGroups());
    fetchInstance({
      method: "GET",
      url: api.getLayoutGroup,
    })
      .then((response) => {
        dispatch(
          ContentActions.fulfilledLayoutGroups(
            response.data.data.map(({name, id}) => ({
              label: name,
              value: id,
            }))
          )
        );
      })
      .catch(() => dispatch(ContentActions.rejectedLayoutGroups()));
  };
};

export const fetchLayoutsByGroupId = (id) => {
  return (dispatch) => {
    dispatch(ContentActions.pendingLayouts());
    fetchInstance({
      method: "GET",
      url: api.getLayoutsByGroupId(id),
    })
      .then((response) => {
        const data = response.data.data.layouts.map((item) => {
          return {
            ...item,
            props: {
              ...item.props,
              customPreview: true,
            },
          };
        });
        dispatch(
          ContentActions.fulfilledLayouts({
            data: orderBy(data, ["id"], ["asc"]),
            type: id,
          })
        );
      })
      .catch(() => dispatch(ContentActions.rejectedLayouts()));
  };
};

export const fetchHeroLayouts = () => {
  return (dispatch) => {
    dispatch(ContentActions.pendingHeroLayouts());
    fetchInstance({
      method: "GET",
      url: api.getHeroLayouts,
    })
      .then((response) => {
        dispatch(
          ContentActions.fulfilledHeroLayouts(response.data?.data[0]?.layouts || [])
        );
      })
      .catch(() => dispatch(ContentActions.rejectedHeroLayouts()));
  };
};

export const fetchTemplates = ({isTemplate, accountId, handleHide, isPayPage, order}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const {userId} = state.user;
    const params = {
      filter: [],
      order,
    };
    if (isTemplate) {
      if (!accountId) {
        params.filter.push({
          column: "is_global",
          value: true,
        });
      } else {
        params.filter.push({
          column: "account.account_id",
          value: accountId,
        });
      }
    } else {
      params.mode = isPayPage ? "user-global" : "user";
    }
    if (userId) {
      params.specify_user = userId;
    }
    let globalTemplates = [];
    if (isTemplate && accountId) {
      try {
        const global = await fetchInstance({
          method: "POST",
          url: api.getIfolioPresets,
          data: {
            order,
            filter: [
              {
                column: "is_global",
                value: true,
              },
            ],
          },
        });
        globalTemplates = global.data.data;
      } catch (e) {
        //
      }
    }

    dispatch(ContentActions.pendingPresets());
    try {
      const response = await fetchInstance({
        method: "POST",
        url: api.getIfolioPresets,
        data: params,
      });
      const data = [...response.data.data, ...globalTemplates];
      if (data.length === 0) {
        dispatch(createPreset({accountId, handleHide}));
      } else {
        dispatch(ContentActions.fulfilledPresets(data));
      }
    } catch (e) {
      dispatch(ContentActions.rejectedPresets());
    }
  };
};
